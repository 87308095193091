import { wikiSignal, wikiSignax } from '../../data'
import { AppRegionEnum } from '../../types/app-region.enum'
import { IFooterPage, PathEnum } from '../../types/pages'

export const pages: Record<AppRegionEnum, IFooterPage[]> = {
  [AppRegionEnum.Ru]: [
    {
      title: 'Продукты',
      pageArray: [
        {
          title: 'Dashboard',
          path: PathEnum.Dashboard,
          exact: true,
        },
        {
          title: 'Inspection',
          path: PathEnum.Inspection,
          exact: true,
        },
        {
          title: 'Docs',
          path: PathEnum.Docs,
          exact: true,
        },
        {
          title: 'Tools',
          path: PathEnum.Tools,
          exact: true,
        },
      ],
    },
    {
      title: 'О нас',
      pageArray: [
        {
          title: 'О компании',
          path: PathEnum.About,
          exact: true,
        },
        {
          title: 'Примеры внедрения',
          path: PathEnum.Implementation,
          exact: true,
        },
        {
          title: 'Вакансии',
          path: PathEnum.Vacancies,
          exact: true,
        },
        {
          title: 'Фирменный стиль',
          path: PathEnum.Brand,
          exact: true,
        },
      ],
    },
    {
      title: 'Материалы',
      pageArray: [
        {
          title: 'Презентации',
          path: PathEnum.Presentation,
          exact: true,
        },
        {
          title: 'Прайс-лист',
          path: PathEnum.Pricelist,
          exact: true,
        },
        {
          title: 'База знаний',
          path: wikiSignal,
          target: '_blank',
        },
      ],
    },
    {
      title: 'Ресурсы',
      pageArray: [
        {
          title: 'News',
          path: PathEnum.News,
          exact: true,
        },
        {
          title: 'Blog',
          path: PathEnum.Blog,
          exact: true,
        },
      ],
    },
  ],
  [AppRegionEnum.Us]: [
    {
      title: 'Products',
      pageArray: [
        {
          title: 'Docs',
          path: PathEnum.Docs,
          exact: true,
        },
        {
          title: 'Inspection',
          path: PathEnum.Inspection,
          exact: true,
        },
        {
          title: 'Tools',
          path: PathEnum.Tools,
          exact: true,
        },
        {
          title: 'Dashboard',
          path: PathEnum.Dashboard,
          exact: true,
        },
      ],
    },
    {
      title: 'Services',
      pageArray: [
        {
          title: 'BIM',
          path: PathEnum.Bim,
          exact: true,
        },
        {
          title: 'Digital',
          path: PathEnum.Digital,
          exact: true,
        },
      ],
    },
    {
      title: 'Resources',
      pageArray: [
        {
          title: 'Articles',
          path: PathEnum.Articles,
          exact: true,
        },
        {
          title: 'News',
          path: PathEnum.News,
          exact: true,
        },
        {
          title: 'Solutions',
          path: PathEnum.Solutions,
          exact: true,
        },
        {
          title: 'Wiki',
          path: wikiSignax,
          target: '_blank',
        },
      ],
    },
    {
      title: 'About',
      pageArray: [
        {
          title: 'Contacts',
          path: PathEnum.Contact,
          exact: true,
        },
        {
          title: 'About SIGNAX',
          path: PathEnum.About,
          exact: true,
        },
        {
          title: 'Brand',
          path: PathEnum.Brand,
          exact: true,
        },
      ],
    },
  ],
}
