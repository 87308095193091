import React from 'react'
import cn from 'classnames'

import PageLink from '../PageLink'
import Link from '../Common/Link'

import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { pages } from './config'
import { AppRegionEnum } from '../../types/app-region.enum'
import NavArrow from '../../svg/NavArrow'

import * as s from './navigation.module.scss'

interface NavigationProps {
  menuExpanded?: boolean
}

const Navigation: React.FC<NavigationProps> = ({ menuExpanded }) => {
  const { region, isDesktop } = React.useContext(GlobalContext)
  const [visibleMenu, setVisibleMenu] = React.useState('')
  const { t } = useTranslations()

  React.useEffect(() => {
    if (!menuExpanded) setVisibleMenu('')
  }, [menuExpanded])

  return (
    <nav className={s.nav}>
      <ul
        className={s.menu}
        itemScope
        itemType="http://www.schema.org/SiteNavigationElement"
      >
        {pages[region as AppRegionEnum].map(
          ({ title, path, exact, pageArray }) => {
            const isVisibleMenu = visibleMenu === title
            return (
              <li key={title} className={s.item}>
                {(isDesktop || !pageArray) && path ? (
                  <ul>
                    <li itemProp="name" className={s.item}>
                      <PageLink
                        className={cn(
                          s.link,
                          s.link_line,
                          region === AppRegionEnum.Ru && s.link_ru
                        )}
                        activeClass={cn(!pageArray && s.active)}
                        path={path}
                        title={title}
                        exact={exact}
                      />
                    </li>
                  </ul>
                ) : (
                  <span
                    onClick={() =>
                      !isDesktop && setVisibleMenu(!isVisibleMenu ? title : '')
                    }
                    title={title}
                    className={cn(
                      s.link,
                      'flex items-center',
                      isVisibleMenu && s.active_menu,
                      region === AppRegionEnum.Ru && s.link_ru
                    )}
                    style={{ cursor: 'default' }}
                  >
                    {t(title)}
                    <NavArrow className={s.dropdown_arrow} />
                  </span>
                )}

                {pageArray && (
                  <div className={cn(s.dropdown, isVisibleMenu && s.active)}>
                    <ul className={s.dropdown_menu}>
                      {pageArray.map((page, idx) => (
                        <li
                          key={page.title + idx}
                          className={s.dropdown_item}
                          itemProp="name"
                        >
                          {page.path &&
                            (page.target ? (
                              <Link
                                href={page.path}
                                target={page.target}
                                title={page.title}
                                rel="nofollow noreferrer"
                                color="white"
                                className={s.dropdown_link}
                                itemProp="url"
                              >
                                {page.title}
                              </Link>
                            ) : (
                              <PageLink
                                className={cn(
                                  s.dropdown_link,
                                  title === t('Products') && 'is-uppercase'
                                )}
                                activeClass={s.active}
                                {...page}
                              />
                            ))}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            )
          }
        )}
      </ul>
    </nav>
  )
}

export default Navigation
